import _ from 'lodash'

export default class Organization {
  constructor(data) {
    this._data = data
    this._children = data.children.map(child => new Organization(child))
  }

  static buildTree(tree) {
    return _.map(tree, organization => new Organization(organization))
  }

  id() {
    return this._data.id
  }

  name() {
    return this._data.name
  }

  sortOrder() {
    return this._data.sortOrder
  }

  children() {
    return _.sortBy(this._children, ['_data.sortOrder', '_data.name'])
  }

  hasChildren() {
    return this._data.children.length > 0
  }

  childrenHaveFocusArea(focusAreaId) {
    if (!this.hasChildren()) { return false }

    return _.some(this.children(), child => {
      if (child.hasFocusArea(focusAreaId)) { return true }
      return child.childrenHaveFocusArea(focusAreaId)
    })
  }

  hasFocusArea(focusAreaId) {
    return _.includes(this._data.focusAreaIds, focusAreaId)
  }
}
