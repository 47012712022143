import 'whatwg-fetch'
import { CSSTransitionGroup } from 'react-transition-group'
import Menu from './Menu'
import MenuLink from './MenuLink'
import OrganizationComparison from './OrganizationComparison'
import React from 'react'
import _ from 'lodash'
import { createRoot } from 'react-dom/client'

export default class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loadingComparisonData: true,
      remainingRequests: props.comparisonUrls.length,
      comparisonData: {
        analyzedDataPoints: [],
        comparisons: [],
        ...props.comparisonData
      }
    }
  }

  componentDidMount() {
    const container = document.getElementById('menu')
    const root = createRoot(container)
    root.render(<MenuLink showMenuPanel={this.showMenuPanel.bind(this)}/>)
    this.fetchComparisonData()
  }

  fetchComparisonData() {
    if (this.props.comparisonUrls.length === 0) {
      this.setState(() => ({ loadingComparisonData: false }))
      return
    }
    this.props.comparisonUrls.map(url => {
      fetch(url, { credentials: 'same-origin' })
        .then(response => response.json())
        .then(data => {
          this.setState(prevState => {
            const { comparisons, ...comparisonData } = prevState.comparisonData
            return {
              loadingComparisonData: prevState.remainingRequests > 0,
              comparisonData: {
                ...comparisonData,
                comparisons: _.sortBy(comparisons.concat(data), 'organizationName')
              },
              remainingRequests: prevState.remainingRequests - 1
            }
          })
        })
    })
  }

  showMenuPanel() {
    this.setState(() => ({
      menuPanel: true,
      detailPanel: false
    }))
  }

  hideMenuPanel() {
    this.setState(() => ({ menuPanel: false }))
  }

  hideDetailPanel() {
    this.setState(() => ({ detailPanel: false }))
  }

  showDetailPanel(detailPanelProps) {
    this.setState(() => ({
      detailPanel: detailPanelProps,
      menuPanel: false
    }))
  }

  renderMenuPanel() {
    if (this.state.menuPanel) {
      return(
        <Menu
          {...this.props.userData}
          panelHide={ this.hideMenuPanel.bind(this) }
          key="menu-panel"
        />
      )
    }
  }

  renderOrganizationComparison() {
    if (this.state.loadingComparisonData ||
         (this.state.comparisonData.analyzedDataPoints.length > 0 ||
          this.state.comparisonData.comparisons.length > 0)) {
      return(
        <OrganizationComparison
          {...this.state.comparisonData}
          hideMenuPanel={this.hideMenuPanel.bind(this)}
          showDetailPanel={this.showDetailPanel.bind(this)}
          hideDetailPanel={this.hideDetailPanel.bind(this)}
          detailPanel={this.state.detailPanel}
        />
      )
    } else if (!this.props.admin) {
      return(
        <div className="center">
          <h2>Ingen tilgjengelige data</h2>
          <h3>Organisasjonen eller organisasjonsnivået du har valgt har ingen data for det valgte fokusområdet.</h3>
          <h4>Du kan velge organisasjonsnivå og fokusområde på menyen oppe til høyre, eller klikke på knappen nedenfor.</h4>

          <button className="button button--primary" onClick={this.showMenuPanel.bind(this)}>
            Vis alternativer
          </button>
        </div>
      )
    }
  }


  render() {
    return(
      <div>
        {this.renderOrganizationComparison()}
        <CSSTransitionGroup
          className="menu-panel"
          transitionName="panel"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}>
          { this.renderMenuPanel() }
        </CSSTransitionGroup>
      </div>
    )
  }
}
