import _ from 'lodash'
import { sprintf } from 'sprintf-js'

export default class MultiLineKpiChart {

  constructor(datasetId, orgs, dateFormat, displayFormat) {
    this._datasetId = datasetId
    this._orgs = this._conformOrgs(orgs)
    this._dateFormat = dateFormat
    this._displayFormat = displayFormat
  }

  highchartsChartDef() {
    return {
      title: null,
      chart: {
        // AKA `$panel-bg-color` in app/assets/stylesheets/variables.css
        backgroundColor: '#EFF2F3',
      },
      legend: {
        maxHeight: 126
      },
      plotOptions: {
        series: { animation: false },
      },
      xAxis: { type: 'datetime' },
      yAxis: {
        min: this._yAxisMin(),
        max: this._yAxisMax(),
        title: { enabled: false },
      },
      series: this._getOrgs().map(this._highchartsLineDef.bind(this)),
    }
  }

  _conformOrgs(orgs) {
    const datasetId = this._datasetId,
          isCorrectDataset = dataset => dataset.datasetId == datasetId,
          findDataset = datasets => datasets.find(isCorrectDataset),
          simplifyAnalysis = analysis => {
            const { dataPoints, preferredRange } =
                  findDataset(analysis.analyzedDataPoints)
            return {
              organizationName: analysis.organizationName,
              dataPoints,
              preferredRange,
            }
          }
    return orgs.map(simplifyAnalysis)
  }

  _highchartsLineDef({organizationName, dataPoints}) {
    const displayFormat = this._displayFormat
    return {
      name: organizationName,
      data: this._prepDataPoints(dataPoints),
      tooltip: {
        xDateFormat: this._dateFormat,
        pointFormatter: function () {
          const data = displayFormat ? sprintf(displayFormat, this.y) : this.y
          return `${this.series.name}: ${data} (n = ${this.sampleSize})`
        },
      },
    }
  }

  _getOrgs() {
    if (!this._nonEmptyOrgs) {
      const isNotEmpty = analysis => analysis.dataPoints.length > 0
      this._nonEmptyOrgs = this._orgs.filter(isNotEmpty)
    }
    return this._nonEmptyOrgs
  }

  _getAllDataPoints() {
    if (!this._allDataPoints) {
      this._allDataPoints = this._getOrgs().map(analysis => analysis.dataPoints)
    }
    return this._allDataPoints
  }

  _prepDataPoints(dataPoints) {
    return dataPoints.map(
      dataPoint => ({
        x: +(new Date(dataPoint.recordedAt)),
        y: parseFloat(dataPoint.kpi),
        sampleSize: dataPoint.sampleSize
      })
    )
  }

  _yAxisMax() {
    const globalRange = this._globalPreferredRange()
    if (!globalRange) {
      return this._maxKpi()
    } else {
      return Math.max(
        this._maxKpi(),
        globalRange.max
      )
    }
  }

  _yAxisMin() {
    const globalRange = this._globalPreferredRange()
    if (!globalRange) {
      return this._minKpi()
    } else {
      return Math.min(
        this._minKpi(),
        globalRange.min
      )
    }
  }

  _globalPreferredRange() {
    const preferredRanges = this._getPreferredRanges()
    if (preferredRanges.length == 0) {
      return null
    } else {
      return {
        min: _.min(preferredRanges.map(range => range.min)),
        max: _.max(preferredRanges.map(range => range.max)),
      }
    }
  }

  _getPreferredRanges() {
    return this._getOrgs()
      .map(analysis => analysis.preferredRange)
      .filter(range => range && range.min && range.max)
  }

  _maxKpi() {
    return _.max(this._parsedKpis())
  }

  _minKpi() {
    return _.min(this._parsedKpis())
  }

  _parsedKpis() {
    return _.flatten(this._getAllDataPoints())
      .map((dataPoint) => parseFloat(dataPoint.kpi))
  }
}
