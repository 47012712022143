import DataPointAnalysisCard from './DataPointAnalysisCard'
import React from 'react'
import _ from 'lodash'

class OrganizationDatasets extends React.Component {

  renderDataPoints() {
    return _.map(this.props.analyzedDataPoints, (analysis) => {
      return (
        <DataPointAnalysisCard
          panelShow={ this.props.panelShow }
          key={ analysis.datasetId }
          organizationName={ this.props.organizationName }
          { ...analysis }
        />
      )
    })
  }

  render() {
    const columnClass = this.props.layout === 'desktop' ? 'multi-column' : 'one-column'
    const headerStyle = this.props.layout === 'mobile' ? { display: 'none' } : {}
    return (
      <div className={ `${columnClass} organization-dataset` } >
        <div className="organization-dataset__wrapper">
          <h2 className="organization-dataset__header" style={headerStyle}>
            { this.props.organizationName }
          </h2>
          { this.renderDataPoints() }
        </div>
      </div>
    )
  }
}

export default OrganizationDatasets
