import Highcharts from 'highcharts'
import MultiLineKpiChart from '../models/MultiLineKpiChart'
import React from 'react'
import SingleLineKpiChart from '../models/SingleLineKpiChart'
import TrendIcon from './TrendIcon'

const dateFormat = '%d. %b %Y'

class DetailPanel extends React.Component {

  constructor(props) {
    super(props)
    this._buildKpiCharts(props)
    this.state = {
      multiLineChart: false,
      chartComparisonType: 'none'
    }
  }

  componentWillReceiveProps(newProps) {
    this._buildKpiCharts(newProps)
  }

  _buildKpiCharts(props) {
    this.singleKpiChart = new SingleLineKpiChart(props.caption, props.dataPoints, props.preferredRange, dateFormat, props.displayFormat)
    this.multiKpiCharts = {
      peers: new MultiLineKpiChart(props.datasetId, [props.currentOrg, ...this._peerOrgs()], dateFormat, props.displayFormat),
      siblings: new MultiLineKpiChart(props.datasetId, [props.currentOrg, ...this._siblingOrgs()], dateFormat, props.displayFormat),
      immediate_children: new MultiLineKpiChart(props.datasetId, [props.currentOrg, ...this._immediateChildOrgs()], dateFormat, props.displayFormat),
      everyone: new MultiLineKpiChart(props.datasetId, [props.currentOrg, ...this._everyoneOrgs()], dateFormat, props.displayFormat)
    }
  }

  _peerOrgs() {
    return this.props.comparisons.filter(comparison => comparison.peer)
  }

  _siblingOrgs() {
    return this.props.comparisons.filter(comparison => comparison.sibling)
  }

  _everyoneOrgs() {
    return this.props.comparisons.filter(comparison => comparison.everyone)
  }

  _immediateChildOrgs() {
    return this.props.comparisons.filter(comparison => comparison.immediate_child)
  }

  renderChart() {
    this.chart = Highcharts.chart(this.refs.chart, this._currentChartDefinition())
    this._adjustForLegendHeight()
  }

  _adjustForLegendHeight() {
    // Highcharts shrinks the chart to fit the legend by default. We want the
    // chart to stay the same size.
    const legend = this.refs.chart.querySelector('.highcharts-legend-box')

    if (legend) {
      const legendHeight = legend.getBoundingClientRect().height
      const boundingRect = this.refs.chart.getBoundingClientRect()
      this.chart.setSize(
        boundingRect.width,
        boundingRect.height + legendHeight,
        false // don't animate the change
      )
    }
  }

  _currentChartDefinition() {
    if (this.state.multiLineChart) {
      return this.multiKpiCharts[this.state.chartComparisonType].highchartsChartDef()
    } else {
      return this.singleKpiChart.highchartsChartDef()
    }
  }

  componentDidMount() {
    this.renderChart()
  }

  componentDidUpdate() {
    this.renderChart()
  }

  renderDataUpdated() {
    if (this.props.latest.recordedAt) {
      const formatDate = date => Highcharts.dateFormat(this._dateFormat, date),
            dateString = formatDate(new Date(this.props.latest.recordedAt))
      return(
        <span className="kpiChart__data-updated-date">Siste data: { dateString }</span>
      )
    } else {
      return null
    }
  }

  renderSampleSize() {
    if (!this.props.latest.sampleSize) { return '' };
    return(<span>n = {this.props.latest.sampleSize }</span>)
  }

  renderMultiChartToggle() {
    const updateChartType = event => {
      const value = event.target.value
      this.setState({ chartComparisonType: value, multiLineChart: value !== 'none' })
    }

    const { populationCategoryID } = this.props.currentOrg
    const options = {
      'none': 'ingen',
      'peers': populationCategoryID == 0 ? undefined : 'tilsvarende organisasjoner',
      'siblings': 'søskenorganisasjoner',
      'immediate_children': populationCategoryID == 0 ? 'underenheter' : undefined,
      'everyone': 'alle'
    }

    return (
      <div>
        <p>Sammenlign med:</p>

        {Object.keys(options).map(option => {
          if (options[option] == undefined) return

          return (
            <div key={option}>
              <input
                type="radio"
                id={option}
                value={option}
                name="chart-comparison"
                onChange={updateChartType}
                checked={this.state.chartComparisonType === option}
              />
              <label className="detail-panel__label" htmlFor={option}>{options[option]}</label>
            </div>
          )
        })}
      </div>
    )
  }

  renderComments() {
    if (this.props.comments) {
      return(
        <div>
          <h4>
            Kommentarer
            <i className="material-symbols-outlined">chat_bubble_outline</i>
          </h4>
          <p>{this.props.comments}</p>
        </div>
      )
    }
  }

  render() {
    return(
      <div className="detail-panel">
        <div className="detail-panel__header">
          <div className="detail-panel__title-bar">
            { this.props.caption }
            <div className="detail-panel__close">
              <button onClick={ this.props.panelHide }>
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <div className="detail-panel__summary">
            { this.props.organizationName || this.props.currentOrg.organizationName }
            <ul>
              <li className={`kpi-card__value-${this.props.color}`}>{this.props.latest.formattedKpi}</li>
              <li><TrendIcon trend={this.props.trend} /></li>
              <li>{ this.renderSampleSize() }</li>
            </ul>
          </div>
        </div>
        <div className="detail-panel__body">
          <div ref="chart"
               className="chart linechart"
               key={`${this.props.datasetId}-${this.props.organizationName}`} />
          { this.renderDataUpdated() }
          { this.renderMultiChartToggle() }
          { this.renderComments() }
          <h4>Beskrivelse</h4>
          <p>{this.props.description}</p>
        </div>
      </div>
    )
  }
}

export default DetailPanel
