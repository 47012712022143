import FocusAreaSelection from './FocusAreaSelection'
import OrganizationTree from './OrganizationTree'
import React from 'react'
import _ from 'lodash'

export default class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.state = { currentFocusArea: this.props.currentFocusArea }
  }

  currentOrganizationId() {
    if (this.props.currentFocusArea.id === this.state.currentFocusArea.id) {
      return this.props.currentOrganizationId
    } else {
      return null
    }
  }

  renderOrganizationsTree() {
    return(
      <OrganizationTree
        key="organization"
        organizationTree={this.props.organizationTree}
        currentOrganizationId={this.currentOrganizationId()}
        focusArea={this.state.currentFocusArea}
      />
    )
  }

  renderFocusAreas() {
    return (
      <FocusAreaSelection
        key="focusAreas"
        currentFocusArea={this.state.currentFocusArea}
        focusAreas={this.props.focusAreas}
        setFocusArea={this.setFocusArea.bind(this)}
      />
    )
  }

  setFocusArea(focusArea) {
    this.setState(() => ({ currentFocusArea: focusArea }))
  }

  renderAdminButton() {
    if (this.props.admin) {
      return(
        <div className="detail-panel__admin">
          <a href="/admin/organizations">Admin</a>
        </div>
      )
    }
  }

  render() {
    return(
      <div className="detail-panel menu-panel">
        <div className="detail-panel__header">
          <div className="detail-panel__title-bar">
            Menu
            <div className="detail-panel__close">
              <button onClick={ this.props.panelHide }>
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
        </div>

        { this.renderFocusAreas() }
        { this.renderOrganizationsTree() }

        <div className="detail-panel__buttons">
          { this.renderAdminButton() }
          <div className="detail-panel__logout">
            <form className="button_to" method="post" action="/users/sign_out">
              <input type="hidden" name="_method" value="delete" autoComplete="off" />
              <button type="submit">
                Logg Av
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
