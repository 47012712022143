import { Controller } from 'stimulus'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from '../components/App.jsx'
import Alert from '../components/Alert.jsx'

const registeredComponents = {
  App, Alert
}

export default class extends Controller {
  connect() {
    const componentName = this.element.dataset.component
    const component = registeredComponents[componentName]

    if (component) {
      const root = createRoot(this.element)
      const props = JSON.parse(this.element.dataset.props)
      root.render(React.createElement(component, props))
    } else {
      throw new Error('Unrecognized React component name!')
    }
  }
}
