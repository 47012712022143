import { CSSTransitionGroup } from 'react-transition-group'
import DetailPanel from './DetailPanel'
import MediaQuery from 'react-responsive'
import OrganizationDatasets from './OrganizationDatasets'
import React from 'react'
import ReactSwipe from 'react-swipe'
import RootOrganization from './RootOrganization'
import _ from 'lodash'

class OrganizationComparison extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      displayComparison: false,
      currentOrg: this.organizations()[0]
    }
  }

  _peerOrgs() {
    return this.props.comparisons.filter(comparison => comparison.peer)
  }

  organizations() {
    const { organizationName, analyzedDataPoints, populationCategoryID } = this.props
    const orgs = this._peerOrgs().map(org => ({
      organizationName: org.organizationName,
      analyzedDataPoints: org.analyzedDataPoints,
      populationCategoryID: org.populationCategoryID,
      rootOrg: false
    }))
    orgs.unshift({ organizationName, analyzedDataPoints, populationCategoryID, rootOrg: true })
    return orgs
  }

  renderFor(layout) {
    let organizations
    if (layout === 'desktop') {
      organizations = _.filter(this.organizations(), org => !org.rootOrg)
    } else {
      organizations = this.organizations()
    }
    return organizations.map(org => (
      <OrganizationDatasets
        panelShow={this.props.showDetailPanel.bind(this)}
        key={org.organizationName}
        layout={layout}
        {...org}
      />
    ))
  }

  renderMobileNav() {
    return(
      <div className={`comparison-nav ${this.comparisonClass()}`} >
        <div className="comparison-nav__previous">
          <a onClick={this.navigatePrev.bind(this)}>
            <i className="material-symbols-outlined">chevron_left</i>
          </a>
        </div>
        <div className="comparison-nav__current-org">
          {this.state.currentOrg.organizationName}
        </div>
        <div className="comparison-nav__next">
          <a onClick={this.navigateNext.bind(this)}>
            <i className="material-symbols-outlined">chevron_right</i>
          </a>
        </div>
     </div>
    )
  }

  comparisonClass() {
    return this.state.displayComparison ? 'with-comparison' : ''
  }

  // This is a polyfill for e.g. `new Event('resize')`.
  newEvent(eventName) {
    if (typeof(Event) === 'function') {
      // Most browsers support this without a problem.
      return new Event(eventName)
    } else {
      // IE 9,10,11 will throw an exception with the above, however.
      // More info and credit for this polyfill:
      // https://stackoverflow.com/a/42089476/202292
      const event = document.createEvent('Event')
      event.initEvent(eventName, true, true)
      return event
    }
  }

  resizeDatasetCards() {
    window.dispatchEvent(this.newEvent('resize'))
  }

  setCurrentOrg(index) {
    this.setState(() => ({ currentOrg: this.organizations()[index] }))
  }

  setCompareDisplay(index) {
    const display = index != 0
    if (display != this.state.displayComparison) {
      this.resizeDatasetCards()
      this.setState(() => ({ displayComparison: display }))
    }
  }

  goToRootOrganization() {
    const rootOrganizationIdx = 0;
    const transitionMilliseconds = 300;
    if (this.refs.reactSwipe) {
      this.refs.reactSwipe.slide(rootOrganizationIdx, transitionMilliseconds)
    } else {
      // Scroll back to the beginning
      this.refs.organizationComparison.children[0].children[1].scrollIntoView()
      window.scrollTo(0, 0)
    }
  }

  renderDetailPanel() {
    if (this.props.detailPanel) {
      return(
        <DetailPanel
          { ...this.props.detailPanel }
          currentOrg={this.state.currentOrg}
          comparisons={this.props.comparisons}
          panelHide={ this.props.hideDetailPanel.bind(this) }
          key="details-panel"
        />
      )
    }
  }

  openDetailsPanelClass() {
    if (this.props.detailPanel) {
      return 'open-panel'
    }
  }

  navigatePrev() { this.refs.reactSwipe.prev() }
  navigateNext() { this.refs.reactSwipe.next() }

  swipeOptions() {
    return {
      continuous: false,
      speed: 300,
      callback: (index, _elem) => {
        this.setCompareDisplay(index)
        this.setCurrentOrg(index)
      }
    }
  }

  rootOrganization(layout) {
    const rootOrg = this.organizations().find(org => org.rootOrg)
    if (this.state.displayComparison || layout === 'desktop') {
      return (
        <RootOrganization
          organization={rootOrg}
          layout={layout}
          goToRootOrganization={this.goToRootOrganization.bind(this)}
          panelShow={this.props.showDetailPanel.bind(this)}
        />
      )
    }
  }

  render() {
    return(
      <div className={`organization-comparison ${this.openDetailsPanelClass()}`} ref="organizationComparison">
        <MediaQuery query="(min-width: 600px)">
          { this.rootOrganization('desktop') }
          { this.renderFor('desktop') }
        </MediaQuery>
        <MediaQuery query="(max-width: 599px)">
          { this.renderMobileNav() }
          <CSSTransitionGroup
            transitionName="current-org"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
            { this.rootOrganization('mobile') }
          </CSSTransitionGroup>
          <ReactSwipe ref="reactSwipe" className={this.comparisonClass()} swipeOptions={this.swipeOptions()}>
            { this.renderFor('mobile') }
          </ReactSwipe>
        </MediaQuery>
        <CSSTransitionGroup
          transitionName="panel"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}>
          { this.renderDetailPanel() }
        </CSSTransitionGroup>
      </div>
    )
  }
}

export default OrganizationComparison
