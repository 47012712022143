import React from 'react';
import _ from 'lodash'

export default class Alert extends React.Component {

  constructor(props) {
    super(props)
    this.state = { showAlert: true }
  }

  dismiss() {
    this.setState((prevState, props) => {
      return { showAlert: false }
    })
  }

  renderAlert() {
    return(
      <div className="alert alert-info">
        {this.props.message}
        <div className="alert__close">
          <i className="material-symbols-outlined" onClick={this.dismiss.bind(this)}>close</i>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.showAlert && this.props.message.length > 0) {
      return this.renderAlert()
    } else {
      return null
    }
  }

}
