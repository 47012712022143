import React from 'react'
import _ from 'lodash'

export default class FocusAreaSelection extends React.Component {
  currentFocusAreaIcon(focusAreaId) {
    if (this.props.currentFocusArea.id === focusAreaId) {
      return <i className="material-symbols-outlined right-icon">check</i>
    }
  }

  render() {
    return (
      <div className="focus-area-menu">
        <div className="focus-area-menu__header">Fokusområder</div>
        <ul className="focus-area-menu__list">
          { _.map(this.props.focusAreas, focusArea =>
            <li key={focusArea.id} className="focus-area-menu__item">
              <a onClick={this.props.setFocusArea.bind(this, focusArea)}>
                {focusArea.name}
                {this.currentFocusAreaIcon(focusArea.id)}
              </a>
            </li>
          )}
        </ul>
      </div>
    )
  }
}
