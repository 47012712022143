import React from 'react';

export default class MenuLink extends React.Component {

  render() {
    return(
      <div className="main-nav__account-nav">
        <a onClick={this.props.showMenuPanel}>
          Menu
          <i className="material-symbols-outlined">menu</i>
        </a>
      </div>
    )
  }
}
