import { Application } from "stimulus"
import Polyfills from "@stimulus/polyfills"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
