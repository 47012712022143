import _ from 'lodash'
import { sprintf } from 'sprintf-js'

export default class SingleLineKpiChart {
  constructor(name, dataPoints, preferredRange, dateFormat, displayFormat) {
    this._name = name;
    this._dataPoints = dataPoints;
    this._preferredRange = preferredRange;
    this._dateFormat = dateFormat;
    this._displayFormat = displayFormat;
  }

  highchartsChartDef() {
    return {
      title: null,
      legend: { enabled: false },
      chart: {
        // AKA `$panel-bg-color` in app/assets/stylesheets/variables.css
        backgroundColor: '#EFF2F3',
      },
      plotOptions: {
        series: { animation: false },
      },
      xAxis: { type: 'datetime' },
      yAxis: {
        min: this._yAxisMin(),
        max: this._yAxisMax(),
        title: { enabled: false },
      },
      series: [ this._highchartsLineDef() ],
    }
  }

  _highchartsLineDef() {
    const displayFormat = this._displayFormat
    return {
      name: this._name,
      data: this._data(),
      tooltip: {
        xDateFormat: this._dateFormat,
        pointFormatter: function () {
          const data = displayFormat ? sprintf(displayFormat, this.y) : this.y
          return `${this.series.name}: ${data} (n = ${this.sampleSize})`
        },
      },
    }
  }

  _data() {
    return this._dataPoints.map(dataPoint => ({
        x: +(new Date(dataPoint.recordedAt)),
        y: parseFloat(dataPoint.kpi),
        sampleSize: dataPoint.sampleSize
      })
    )
  }

  _yAxisMax() {
    const range = this._preferredRange
    if (range && range.max && range.max > this._maxKpi()) {
      return range.max
    } else {
      return Math.ceil(this._maxKpi())
    }
  }

  _yAxisMin() {
    const range = this._preferredRange
    if (range && range.min && range.min < this._minKpi()) {
      return range.min
    } else {
      return Math.floor(this._minKpi())
    }
  }

  _maxKpi() {
    return _.max(this._parsedKpis())
  }

  _minKpi() {
    return _.min(this._parsedKpis())
  }

  _parsedKpis() {
    return this._dataPoints.map((dataPoint) => parseFloat(dataPoint.kpi))
  }
}
