import Organization from '../models/Organization'
import React from 'react'
import _ from 'lodash'

export default class OrganizationTree extends React.Component {

  constructor(props) {
    super(props)
    this.organizations = Organization.buildTree(this.props.organizationTree)
  }

  rootOrganization() {
    return this.organizations[0]
  }

  currentOrganizationIndicator(organization) {
    if (this.props.currentOrganizationId && organization.id() === this.props.currentOrganizationId) {
      return(<i className="material-symbols-outlined right-icon">check</i>)
    }
  }

  renderChildren(organization) {
    if (organization.hasChildren()) {
      return(
        <ul>
          {_.map(organization.children(), child => this.renderOrganization(child))}
        </ul>
      )
    }
  }

  renderOrganization(organization) {
    const focusAreaId = this.props.focusArea.id
    if (organization.hasFocusArea(focusAreaId) || organization.childrenHaveFocusArea(focusAreaId)) {
      return(
        <li key={organization.id()} className="organization-menu__item">
          {this.organizationLink(organization)}
          {this.renderChildren(organization)}
        </li>
      )
    }
  }

  organizationLink(organization) {
    if (organization.hasFocusArea(this.props.focusArea.id)) {
      return(
        <a href={`/organizations/${organization.id()}?focus_area_id=${this.props.focusArea.id}`}>
          {organization.name()}
          {this.currentOrganizationIndicator(organization)}
        </a>
      )
    } else {
      return(
        <a className="disabled">
          {organization.name()}
          {this.currentOrganizationIndicator(organization)}
        </a>
      )
    }
  }

  render() {
    return (
      <div className="organization-menu">
        <div className="organization-menu__header">Organisasjoner</div>
        <ul className="organization-menu__list">
          {this.renderOrganization(this.rootOrganization())}
        </ul>
        <div className="organization-menu__footnote">
          Enheter som er markert med grått kan ikke velges fordi det ikke finnes data for det valgte fokusområdet på det organisasjonsnivået.
        </div>
      </div>
    )
  }
}
