import React from 'react'

export default class TrendIcon extends React.Component {

  iconName() {
    const { trend } = this.props;
    if (trend === 'up') { return 'trending_up' }
    if (trend === 'down') { return 'trending_down' }
    return 'trending_flat'
  }

  render() {
    return(
      <span className="trend">
        <i className="material-symbols-outlined">{this.iconName()}</i>
      </span>
    )
  }
}
