import DataPointAnalysisCard from './DataPointAnalysisCard'
import React from 'react'
import _ from 'lodash'

export default class RootOrganization extends React.Component {
  renderSampleSize(sampleSize) {
    const sampleString = sampleSize ? `n = ${sampleSize}` : ''
    return <div className="comparison-dataset__card-sample-size">{ sampleString }</div>
  }

  renderDataPoints() {
    return _.map(this.props.organization.analyzedDataPoints, analysis => (
        <DataPointAnalysisCard
          panelShow={ this.props.panelShow }
          key={ analysis.datasetId }
          organizationName={ this.props.organizationName }
          rootOrg={ true }
          { ...analysis }
        />
      )
    )
  }

  render() {
    return (
      <div className="comparison-dataset__current-org">
        <div className="comparison-nav__home">
          <a onClick={ this.props.goToRootOrganization.bind(this) } className="comparison-nav__home-link">
            <i className="material-symbols-outlined">home</i>
            <span className="comparison-nav__home-text">
              { this.props.layout === 'desktop' && this.props.organization.organizationName }
            </span>
          </a>
        </div>

        <div className="comparison-dataset__wrapper">
          { this.renderDataPoints() }
        </div>
      </div>
    )
  }
}
