import React from 'react'
import TrendIcon from './TrendIcon'
import _ from 'lodash'

class DataPointAnalysisCard extends React.Component {
  latest() {
    return this.props.latest
  }

  renderSampleSize() {
    const sampleString = this.latest().sampleSize ? `n = ${this.latest().sampleSize}` : ''
    return <span>{ sampleString }</span>
  }

  rootOrgClass() {
    return this.props.rootOrg ? 'kpi-card__root-org' : ''
  }

  showDetailPanel() {
    const { props } = this
    this.props.panelShow(props)
  }

  renderPrivateSubHeader() {
    const { sharingLevel } = this.props
    if (sharingLevel === 'private') {
      return (
        <span>
          <i className="material-symbols-outlined kpi-card__sub-header-icon">visibility_off</i>
          Private
        </span>
      )
    }
  }

  isShowingData() {
    return _.includes([ 'shared', 'private' ], this.props.sharingLevel)
  }

  canOpenPanel() {
    return _.includes([ 'shared', 'private', 'disabled' ], this.props.sharingLevel)
  }

  cardClass() {
    const { sharingLevel } = this.props
    switch(sharingLevel) {
      case 'hidden':
        return 'kpi-card--hidden'
        break
      case 'private_hidden':
        return 'kpi-card--private-hidden'
        break
      case 'disabled':
        return 'kpi-card--disabled'
        break
      default:
        return 'kpi-card'
    }
  }

  renderCardBody() {
    const { sharingLevel } = this.props
    if (sharingLevel === 'hidden') {
      return(
        <div className="kpi-card__body">
          <p className="kpi-card__unavailable-explanation">
            Dette kortet er markert som “privat.” Du må dele dette kortet for å se tilsvarende data for andre.
          </p>
        </div>
      )
    } else if (sharingLevel === 'private_hidden') {
      return(
        <div className="kpi-card__body">
          <i className="material-symbols-outlined kpi-card__unavailable-value-icon">visibility_off</i>
          <div className="kpi-card__unavailable-label">Privat</div>
        </div>
      )
    } else if (sharingLevel === 'disabled') {
      return(
        <div className="kpi-card__body">
          <i className="material-symbols-outlined kpi-card__unavailable-value-icon">do_not_disturb</i>
          <div className="kpi-card__unavailable-label">Deaktivert</div>
        </div>
      )
    } else {
      return(
        <div className="kpi-card__body">
          <div className={`kpi-card__kpi-value kpi-card__value-${this.props.color}`}>
            { this.latest().formattedKpi }
          </div>
        </div>
      )
    }
  }

  renderComments() {
    if (this.props.comments) {
      return(
        <div className="kpi-card__comments">
          <i className="material-symbols-outlined">chat_bubble_outline</i>
        </div>
      )
    }
  }

  renderCardFooter() {
    if (this.isShowingData()) {
      return(
        <div className="kpi-card__footer">
          <div className="kpi-card__trend">
            <TrendIcon trend={this.props.trend} />
          </div>
          { this.renderComments() }
          <div className="kpi-card__sample-size">
            { this.renderSampleSize() }
          </div>
        </div>
      )
    } else if (this.canOpenPanel()) {
      return(
        <div className="kpi-card__footer">
          { this.renderComments() }
        </div>
      )
    }
  }

  render() {
    return(
      <div className={`${this.cardClass()} ${this.rootOrgClass()}`} onClick={ this.canOpenPanel() && this.showDetailPanel.bind(this) }>
        <div className="kpi-card__header">
          { this.props.caption }
          <div className="kpi-card__sub-header">
            { this.renderPrivateSubHeader() }
          </div>
        </div>
        { this.renderCardBody() }
        { this.renderCardFooter() }
      </div>
    )
  }
}

export default DataPointAnalysisCard
